import React from 'react';
import Layout from "../../components/Layout";
import SEO from "../../components/Seo";
import MainFrontpage from "../../components/MainFrontpage";
import MainLeftColumn from "../../components/MainLeftColumn";
import MainRightColumn from "../../components/MainRightColumn";

// Hreflang data

// Hreflang data
const alternateLangs = [
  {
    hreflang: "en",
    href: "/en"
  },
  {
    hreflang: "da",
    href: "/da"
  },
  {
    hreflang: "de",
    href: "/de"
  },
  {
    hreflang: "se",
    href: "/se"
  },
  {
    hreflang: "no",
    href: "/no"
  },
  {
    hreflang: "nl",
    href: "/nl"
  },
  {
    hreflang: "x-default",
    href: "/en"
  }
];

const Index = ({ location }) => { 
  return (
    <Layout location={location} alternateLangs={alternateLangs}>
      <React.Fragment>
        <SEO
          title="Blue River Mountains | Google Analytics & SEO Konsulttjänster"
          lang="se"
          description="Konsulttjänster för SEO & Webbanalys på frilansbasis. Lär dig om SEO eller Google Analytics i våra handledningar eller kontakta oss för hjälp med mer avancerade problem."
          image="homepage-screenshot"
          alternateLangs={alternateLangs}
          canonical="/se"
        />
        <MainFrontpage>
          <MainLeftColumn />
          <MainRightColumn />
        </MainFrontpage>
      </React.Fragment>
    </Layout>
  );
};

export default React.memo(Index);

